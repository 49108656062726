import Slider from "react-slick";
import i1 from "../../assets/web/instruments/i1.png";
import i2 from "../../assets/web/instruments/i2.jpg";
import i3 from "../../assets/web/instruments/i3.jpeg";
import i4 from "../../assets/web/instruments/i4.jpg";
import i5 from "../../assets/web/instruments/i5.jpg";
import i6 from "../../assets/web/instruments/i6.jpg";
import i7 from "../../assets/web/instruments/i7.jpg";
import i8 from "../../assets/web/instruments/i8.jpg";
import i9 from "../../assets/web/instruments/i9.jpg";
import i10 from "../../assets/web/instruments/i10.jpg";

const Instrumentos = () => {
  const content = [
    {
      img: i1,
      title: "Analizador de seguridad electrica",
      description:
        "Instrumento para mediciones de seguridad electrica, aplicados a todo tipo de equipo electrico",
    },
    {
      img: i2,
      title: "Analizador de flujo de gases",
      description:
        "Analizador para equipos medicos respiratorios incluidos los ventiladores neonatales y de alta frecuencia",
    },
    {
      img: i3,
      title: "Simulador de senales de ECG",
      description:
        "Genera de manera artificial señales eléctricas conocidas, confiables y repetibles que representan la actividad eléctrica cardíaca, con la finalidad de evaluar periódicamente el funcionamiento de electrocardiógrafos y monitores cardíacos.",
    },
    {
      img: i4,
      title: "Tacometro digital optico/contacto",
      description: "",
    },
    {
      img: i5,
      title: "Termometro digital",
      description: "",
    },
    {
      img: i6,
      title: "Anemometro de hilo caliente",
      description: "",
    },
    {
      img: i7,
      title: "Luxometro",
      description: "",
    },
    {
      img: i8,
      title: "Megometro",
      description: "",
    },
    {
      img: i9,
      title: "Decibelimetro/Sonometro",
      description: "",
    },
    {
      img: i10,
      title: "Micropipeta de pecision calibrada",
      description: "",
    },
  ];

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <section
      id="instrumentos"
      style={{
        backgroundColor: "white",
        height: "100vh",
        padding: "10rem 0",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h1 className="title  is-3 has-text-centered">Nuestros instrumentos</h1>
      <div style={{ width: "100%" }}>
        <Slider {...settings}>
          {content.map((client, index) => (
            <div key={index} style={{ position: "relative" }}>
              <div
                style={{
                  height: "400px",
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={client.img}
                  alt={client.name}
                  style={{
                    display: "block",
                    objectFit: "cover",
                  }}
                />
              </div>
              <h3 className="subtitle has-text-centered">{client.title}</h3>
              <p className="has-text-centered">{client.description}</p>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Instrumentos;
