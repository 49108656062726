import { faFacebook, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faStreetView,faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Footer = () => {
  return (
    <footer
      className="p-0 full-width transparency"
      style={{ position: "sticky", bottom: 0, paddingBottom: "2.5em" ,  backgroundColor: "rgba(255, 255, 255, 200)" }}
    >
      <div className="has-text-centered pt-4">
        <p>
          <strong>
            Soluciones Biomedicas Peruanas S.R.L. RUC:20606103922{" "}
          </strong>
          <br />
          <span>
            Urb. San Francisco, Jiron Espinar Nro.: 21, Wanchaq, Cusco
          </span>
          <br />
        </p>
      </div>



       <div
      style={{
        position: "sticky",
        bottom: "1rem",
        height: 0,
        display: "flex",
        justifyContent: "center",
        gap: "5rem",
      }}
    >
      <a
        href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=sbperuanas@gmail.com"
        rel="noreferrer"
        target="_blank"
        className="ml-4 mr-4"
      >
        <FontAwesomeIcon icon={faEnvelope} size="2x" />
      </a>
      <a
        href="https://www.facebook.com/groups/395326574787053/about"
        className="ml-4 mr-4"
      >
        <FontAwesomeIcon icon={faFacebook} size="2x" />
      </a>
      <a
        href="https://web.whatsapp.com/send?phone=51984013138&amp;text=Hola. ¿En qué podemos ayudarte?"
        className="ml-4 mr-4"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
      </a>
      <a
        href="https://www.google.com/maps/place/13%C2%B031'41.4%22S+71%C2%B057'33.5%22W/@-13.5282121,-71.9595736,19z/data=!4m4!3m3!8m2!3d-13.528173!4d-71.959292?coh=164777&entry=tt"
        className="ml-4 mr-4"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" />
      </a>
    </div>
    </footer>
  );
};

export default Footer;
