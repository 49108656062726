import Slider from "react-slick";
import ahseco from "../../assets/web/clients/ahseco.jpeg";
import diagnostica from "../../assets/web/clients/diagnostica.png";
import essalud_cusco from "../../assets/web/clients/essalud_cusco.jpg";
import lorena from "../../assets/web/clients/lorena.png";
import mac_salud from "../../assets/web/clients/mac_salud.jpg";
import pardo from "../../assets/web/clients/pardo.jpg";
import peruano_suiza from "../../assets/web/clients/peruano_suiza.jpg";
import regional from "../../assets/web/clients/regional.png";
import unsacc from "../../assets/web/clients/unsaac.jpg";

const Clientes = () => {
  const clients = [
    { img: pardo, name: "Clinica Pardo" },
    { img: mac_salud, name: "Clinica Mac Salud" },
    { img: peruano_suiza, name: "Peruano Suiza" },
    { img: diagnostica, name: "Diagnostica Peruana" },
    { img: ahseco, name: "pardo" },
    { img: essalud_cusco, name: "pardo" },
    { img: lorena, name: "Hospital Antonio Lorena" },
    { img: regional, name: "Hospital Regional Cusco" },
    { img: unsacc, name: "Unsaac" },
  ];

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <section
      id="clientes"
      style={{
        backgroundColor: "white",
        height: "100vh",
        padding: "5rem",
        justifyContent: "space-around",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h1 className="title  is-3 has-text-centered">Nuestros clientes</h1>
      <div style={{ width: "100%", height: "50vh" }}>
        <Slider {...settings}>
          {clients.map((client, index) => (
            <div key={index}>
              <div
                style={{
                  height: "200px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={client.img}
                  alt={client.name}
                  style={{
                    height: "auto",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "70%",
                  }}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};
export default Clientes;
