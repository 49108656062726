import Clientes from "./Clientes";
import Contactenos from "./Contactenos";
import Footer from "./Footer";
import Icons from "./Icons";
import Instrumentos from "./Instrumentos";
import Navbar from "./Navbar";
import Nosotros from "./Nosotros";
import Productos from "./Productos";
import Servicios from "./Servicios";
import Whatsapp from "./Whatsapp";

const Index = () => {
  return (
    <>
      <Navbar />
      <Nosotros />
      <Servicios />
      <Clientes />
      <Productos />
      <Instrumentos />
      <Contactenos />
      <Icons />
      <Footer />
      <Whatsapp />
    </>
  );
};
export default Index;
