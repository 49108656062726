import whatsapp from "../../assets/web/other/whatsapp.png";
const Whatsapp = () => {
  return (
    <a
      href="https://web.whatsapp.com/send?phone=51984013138&amp;text=Hola. ¿En qué podemos ayudarte?"
      target="_blank"
      rel="noreferrer"
    >
      <img
        className="animate__animated animate__pulse animate__infinite animate_"
        src={whatsapp}
        alt="whatsapp"
        style={{
          width: "5em",
          bottom: "5rem",
          marginLeft: "2rem",
          zIndex: 2000,
          position: "fixed",
        }}
      />
    </a>
  );
};
export default Whatsapp;
