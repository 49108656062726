import Slider from "react-slick";
import mantenimiento from "../../assets/web/us/mantenimiento.jpg";
import capacitacion from "../../assets/web/us/capacitacion.jpeg";
import proyectos from "../../assets/web/us/proyectos.jpg";
import asesoramiento from "../../assets/web/us/asesoramiento.jpeg";
import potenciacion from "../../assets/web/us/potenciacion.jpeg";
const Servicios = () => {
  const content = [
    {
      img: mantenimiento,
      text: "Mantenimientos preventivos y/o correctivos",
    },
    {
      img: asesoramiento,
      text: "Venta de equipamiento en general",
    },
    {
      img: capacitacion,
      text: "Asesoría y capacitaciones",
    },
    {
      img: potenciacion,
      text: "Repotenciación de equipos médicos y/o laboratorio",
    },
    {
      img: proyectos,
      text: "Diseño de proyectos",
    },
  ];

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <section
      id="servicios"
      style={{
        backgroundColor: "linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)",
        height: "100vh",
        padding: "10rem 0",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h1 className="title  is-3 has-text-centered">
        La calidad es nuestra prioridad
      </h1>
      <div style={{ width: "100%" }}>
        <Slider {...settings}>
          {content.map((client, index) => (
            <div key={index}>
              <div
                style={{
                  height: "400px",
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={client.img}
                  alt={client.name}
                  style={{
                    display: "block",
                    objectFit: "cover",
                  }}
                />
              </div>
              <h3
                className="subtitle"
                style={{
                  zIndex: "200",
                  textAlign: "center",
                }}
              >
                {client.text}
              </h3>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Servicios;
