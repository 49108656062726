import { useState } from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/web/logo.jpg";
const Navbar = () => {
  const history = useHistory();

  const [burger, setBurger] = useState(false);
  const closeMenu = () => {
    setBurger(false);
  };
  return (
    <nav
      className="navbar "
      style={{ position: "fixed", width: "100vw", marginBottom: "4rem" }}
    >
      <div className="navbar-brand">
        <a
          href="#nosotros"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            alt="Soluciones biomedicas peruanas"
            style={{ maxHeight: "5rem", margin: "0 0.5em" }}
          />
          <h3 className="title is-3 has-text-primary">
            Soluciones Biomedicas Peruanas
          </h3>
        </a>

        <div
          className={`navbar-burger burger ${burger && "is-active"}`}
          data-target="navMenuExample1"
          onClick={() => {
            setBurger((b) => (b = !b));
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div
        id="navMenuExample1"
        className={`navbar-menu ${burger && "is-active"}`}
      >
        <div className="navbar-end">
          <a className="navbar-item" href="#nosotros" onClick={closeMenu}>
            Nosotros
          </a>
          <a className="navbar-item" href="#servicios" onClick={closeMenu}>
            Servicios
          </a>
          <a className="navbar-item" href="#productos" onClick={closeMenu}>
            Productos
          </a>
          <a className="navbar-item" href="#instrumentos" onClick={closeMenu}>
            Instrumentos
          </a>
          <a className="navbar-item" href="#clientes" onClick={closeMenu}>
            Clientes
          </a>
          <a className="navbar-item" href="#contactenos" onClick={closeMenu}>
            Contactenos
          </a>
          <div className="navbar-item" style={{ marginRight: "1em" }}>
            <div className="field is-grouped">
              <p className="control">
                <button
                  className="button is-primary"
                  onClick={() => history.push("/login")}
                >
                  Acceder
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
