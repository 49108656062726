const Productos = () => {
  return (
    <section
      id="productos"
      style={{
        backgroundImage: "linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)",
        height: "100vh",
        padding: "5rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <h1>Productos</h1>
      <p>This text is awesome</p>
    </section>
  );
};
export default Productos;
