import { StoreProvider, createStore } from "easy-peasy";
import model from "../js/model";
import Switcher from "./admin/Switcher";

const store = createStore(model);

const App = () => {
  return (
    <StoreProvider store={store}>
      <Switcher />
    </StoreProvider>
  );
};

export default App;
