import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faStreetView } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Contactenos = () => {
  return (
    <section
      id="contactenos"
      className="columns"
      style={{
        backgroundImage: "linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)",
        height: "100vh",
        padding: "8em 2em",
        margin: "0",
      }}
    >
      <div
        className="column"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h3 className="title">Contactenos:</h3>
        <table className="table">
          <tr>
            <th>Telefonos:</th>
            <td>
              <a
                href="https://web.whatsapp.com/send?phone=51971776607&amp;text=Hola. ¿En qué podemos ayudarte?"
                className="ml-4 mr-4"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faWhatsapp} size="lg" /> (+51) 971 776607
              </a>
            </td>
          </tr>
          <tr>
            <th></th>
            <td>
              <a
                href="https://web.whatsapp.com/send?phone=51984013138&amp;text=Hola. ¿En qué podemos ayudarte?"
                className="ml-4 mr-4"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faWhatsapp} size="lg" /> (+51) 984 013138
              </a>
            </td>
          </tr>
          <tr>
            <th>Emails:</th>
            <td>
              <a
                href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=sbperuanas@gmail.com"
                rel="noreferrer"
                target="_blank"
                className="ml-4 mr-4"
              >
                <FontAwesomeIcon icon={faEnvelope} size="lg" />{" "}
                sbperuanas@gmail.com
              </a>
            </td>
          </tr>
          <tr>
            <th></th>
            <td>
              <a
                href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=sbpserviciotecnico@gmail.com"
                rel="noreferrer"
                target="_blank"
                className="ml-4 mr-4"
              >
                <FontAwesomeIcon icon={faEnvelope} size="lg" />{" "}
                sbpserviciotecnico@gmail.com
              </a>
            </td>
          </tr>
          <tr>
            <th>Direccion:</th>
            <td>
              <a
                href="https://www.google.com/maps/place/13%C2%B031'41.4%22S+71%C2%B057'33.5%22W/@-13.5282121,-71.9595736,19z/data=!4m4!3m3!8m2!3d-13.528173!4d-71.959292?coh=164777&entry=tt"
                className="ml-4 mr-4"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faStreetView} size="lg" /> Urb. San
                Francisco
                <br />
                Jirón Espinar Nro. 21
                <br />
                Wanchaq, Cusco
              </a>
            </td>
          </tr>
        </table>
      </div>
      <div className="column" style={{}}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d969.7807716178337!2d-71.95969817076595!3d-13.528036999405769!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbbfd14624a38ccf3!2zMTPCsDMxJzQwLjkiUyA3McKwNTcnMzIuOSJX!5e0!3m2!1sen!2spe!4v1675000920986!5m2!1sen!2spe"
          style={{ border: 0, width: "100%", height: "100%" }}
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title="map"
        />
      </div>
    </section>
  );
};
export default Contactenos;
