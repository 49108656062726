import { Switch, Route, useLocation } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import NextMaintenances from "./NextMaintenances";
import NewReport from "./NewReport";
import Home from "./Home";
import DeviceDetails from "./DeviceDetails";
import PrintFormat from "./PrintFormat";
import SaveFormat from "./SaveFormat";
import Login from "./Login";
import MaintenanceDetails from "./MaintenanceDetails";
import TopNavigator from "./TopNavigator";
import Footer from "./Footer";
import background from "../../assets/admin/brain.jpeg";
import Index from "../web/Index";

const Switcher = () => {
  const user = useStoreState((state) => state.user);
  const path = useLocation().pathname;
  const showLayout = !path.includes("/print_format/") && path !== "/";

  console.log(path);
  return (
    <Switch>
      <div
        className="m-0"
        style={{
          backgroundImage: showLayout ? `url(${background})` : "none",
          backgroundSize: showLayout ? "100% 105%" : "none",
          backgroundPositionY: showLayout ? "-1em" : "none",
          backgroundRepeat: showLayout ? "no-repeat" : "none",
          minHeight: showLayout ? "100vh" : "none",
        }}
      >
        {showLayout && <TopNavigator />}
        {user.name && (
          <div
            className="columns full-width m-0"
            style={{ padding: "1em 2em" }}
          >
            <Route path="/home" component={Home} />
            <Route path="/new_report" component={NewReport} />
            <Route path="/next_maintenances" component={NextMaintenances} />
            <Route
              path="/device_details/:device_id"
              component={DeviceDetails}
            />
            <Route
              path="/maintenance_details/:maintenance_id"
              component={MaintenanceDetails}
            />
            <Route path="/print_format/:device_id" component={PrintFormat} />
            <Route path="/save_format/:device_id" component={SaveFormat} />
          </div>
        )}

        <Route path="/login" component={Login} />
        {showLayout && <Footer />}
        <Route exact path="/" component={Index} />
      </div>
    </Switch>
  );
};

export default Switcher;
